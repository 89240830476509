<template>
  <div>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
    />
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'advertiser-ratings',
      singularName: 'advertiser-ratings',
      searchFields: {
        customer_id: this.$route.params.customer_id,
      },
    }
  },
}
</script>
